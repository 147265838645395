<template>
  <div>
    <v-app>
      <Header />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Carousel style="border-radius: 0px" />
      <br />

      <Imgs />
      <Footer />
    </v-app>
  </div>
</template>

<script>
import Header from "./components/HeaderView.vue";
import Carousel from "./components/CareouselView.vue";
import Footer from "./components/FooterView.vue";
import Imgs from "./components/ImgsView.vue";
export default {
  name: "App",

  components: { Header, Carousel, Footer, Imgs },

  data: () => ({}),
};
</script>
<style>
</style>