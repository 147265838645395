<template>
  <div>
    <v-carousel hide-delimiters :show-arrows="false" cycle max-height="200px">
      <v-carousel-item v-for="slid in slides" :key="slid" :src="slid" cover>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      slides: [
        require("@/assets/19.png"),
        require("@/assets/2.png"),
        require("@/assets/3.png"),
        require("@/assets/4.png"),
        require("@/assets/1.png"),
        require("@/assets/5.png"),
        require("@/assets/7.png"),
        require("@/assets/8.png"),
        require("@/assets/9.png"),
        require("@/assets/12.png"),
        require("@/assets/15.png"),
        require("@/assets/18.png"),
      ],
    };
  },
};
</script>
<style>
.v-carousel .v-window-item {
  position: absolute;
  top: 0;
  width: 100%;
  transition-duration: 3s, 1s;
}
</style>