<template>
  <div>
    <v-container fluid>
      <v-row style="background-color: rgb(193, 194, 191)" class="black--text">
        <v-col cols="12" align="center">
          <a href="https://m.facebook.com/alamerhotel/" style="text-decoration: none">
            <v-icon color="blue"> mdi-facebook </v-icon>
            alamerhotel
          </a>
        </v-col>
        <v-col cols="12" align="center">
          <v-icon color="success"> mdi-whatsapp </v-icon> +963932349898</v-col>
        <v-col cols="12" align="center">
          <v-icon> mdi-email </v-icon>
          alamerhotel@gmail.com</v-col>
        <v-col align="center">
          <v-icon>mdi-phone</v-icon>
          +963112116600
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {};
</script>