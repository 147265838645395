<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6" v-for="slide in slides" :key="slide.src">
          <v-img
            :src="slide.src"
            style="border-radius: 8px"
            @click="dialog2(slide.src)"
          ></v-img>
          <v-dialog v-model="dialog" style="border-radius: 0px">
            <v-img :src="dialog_src"> </v-img>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      slides: [
        { src: require("@/assets/22.png") },
        { src: require("@/assets/21.png") },
        { src: require("@/assets/20.png") },
        { src: require("@/assets/19.png") },
        { src: require("@/assets/17.png") },
        { src: require("@/assets/16.png") },
        { src: require("@/assets/15.png") },
        { src: require("@/assets/14.png") },
        { src: require("@/assets/13.png") },
        { src: require("@/assets/12.png") },
        { src: require("@/assets/11.png") },
        { src: require("@/assets/9.png") },
        { src: require("@/assets/8.png") },
        { src: require("@/assets/7.png") },
        { src: require("@/assets/6.png") },
        { src: require("@/assets/5.png") },
        { src: require("@/assets/4.png") },
        { src: require("@/assets/3.png") },
        { src: require("@/assets/2.png") },
        { src: require("@/assets/1.png") },
      ],
      dialog: false,
      dialog_src: "@/assets/19.png",
    };
  },
  methods: {
    dialog2(src) {
      this.dialog_src = src;
      this.dialog = true;
      console.log(this.dialog_src);
    },
    cansle_dialog() {
      this.dialog = false;
    },
  },
};
</script>